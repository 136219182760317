@import "variables";
@import "../node_modules/@clr/icons/clr-icons.min.css";
@import "./assets/fonts/fonts.css";
@import "./theme";
@import "node_modules/@clr/ui/src/utils/_components.clarity.scss";
@import 'node_modules/ngx-toastr/toastr.css';
@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';
@import 'font-awesome/scss/font-awesome.scss';

// To change NgSelect theme colors, modify:
//.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {}
//.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {}

.ng-select {
  .ng-select-container {
    border-radius: $border-radius;
  }

  &:not(.ng-select-disabled) {
    .ng-select-container .ng-value-container {
      .ng-placeholder {
        color: $br-text-dark;
      }

      .ng-input > input {
        padding: initial;
      }
    }
  }
}

.ng-select span.ng-clear-wrapper {
  width: 1.25rem;

  span.ng-clear {
    font-size: 1.25rem;

  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  font-size: 1rem;
  vertical-align: bottom;
}

.ng-select-custom-checkbox {
  vertical-align: middle;
  margin-right: 0.3rem;
}

.ng-select-custom-label {
  vertical-align: middle;
}

.clipboard-copy-success {
  font-weight: 500;
  color: #2F8400;
  padding: 0.1rem 0.3rem;
  border-radius: 4px;
  margin-left: 0.5rem;
}

.clipboard-copy-success-modal {
  font-size: 0.75rem;
}

.copy-to-clipboard-btn {
  outline: none!important;
}

.copy-to-clipboard-icon {
  margin-bottom: 0.2rem!important;
}

.table-actions {
  width: 5rem!important;
}

.datagrid-align-cells {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

/*****************************************************************
 * Global Styles (Primarily to override default Clarity styles)
 */
clr-main-container.open-hamburger-menu > app-navigation > clr-header > div.branding > a > div.broadridge-logo {
  background-image: url(assets/images/br-logo-blue.svg) !important;
}

.datagrid .datagrid-column.asc .datagrid-column-flex::after, .datagrid .datagrid-head .asc.datagrid-row-actions .datagrid-column-flex::after, .datagrid .datagrid-column.desc .datagrid-column-flex::after, .datagrid .datagrid-head .desc.datagrid-row-actions .datagrid-column-flex::after {
  width: $table-header-icon-size;
  height: $table-header-icon-size;
}

.datagrid .datagrid-column .datagrid-filter-toggle, .datagrid .datagrid-head .datagrid-row-actions .datagrid-filter-toggle {
  width: $table-header-icon-size;
  height: $table-header-icon-size;
}

.nav .nav-link.nav-item {
  outline: none!important;
}

// Fix Clarity datagrid loading indicator display issue.
.datagrid-spinner {
  z-index: 1;
  margin-top: 12px;
}

.datagrid-host {
  // Hide illustrative checkbox in the Clarity datagrid footer that indicates rows selected.
  .datagrid-footer {
    .clr-form-control-disabled {
      display: none;
    }
  }
}

.datagrid-table {
  min-height: 18rem;
  outline: none;
}

.datagrid-cell {
  overflow-wrap: break-word;
}

.btn {
  overflow: visible;

  &.btn-no-caps {
    text-transform: none;
    letter-spacing: normal;
    font-size: 0.581667rem;

    &:focus {
      outline: none;
    }
  }

  &.btn-br {
    font-size: 13px;
    border-color: transparent;
    box-shadow: $box-shadow-br-alt;
    transition: box-shadow 0.2s;

    &.btn-white {
      background-color: $br-white;

      &:not(:disabled) {
        color: rgba($br-midnight-blue, 0.9);
      }

      &:hover {
        color: $br-midnight-blue;

        &:not(:disabled) {
          box-shadow: $box-shadow-br-alt-hover;
        }
      }
    }

    &.btn-link {
      background-color: transparent;
      box-shadow: none;
      min-width: 2.5rem;

      &:hover:not(:disabled) {
        box-shadow: none;
      }

      &.text-dark {
        color: $br-text-dark;
      }
    }
  }
}

.text-highlight {
  background: yellow;
}

.text-faded {
  color: $br-text-faded;
}

.text-dark {
  color: $br-text-dark;
}

// Blue rectangle design elements on Clarity modals
.modal-title {
  .rectangle {
    position: absolute;
    margin-top: -64px;
    margin-left: -63px;
    border-top-left-radius: $border-radius;

    &.r1 {
      height: 20px;
      width: 120px;
      background-color: $br-cyan-alt;
      z-index: 1;
      opacity: 0.8;
      margin-top: -24px;
      margin-left: -28px;
    }

    &.r2 {
      height: 70px;
      width: 40px;
      background-color: $br-blue-alt;
      margin-top: -24px;
      margin-left: -27px;
    }
  }
}

// SweetAlert overrides
.swal-text {
  text-align: center;
}

clr-date-container.clr-form-control {
  margin-top: 0;

  label.clr-control-label {
    display: none;
  }
}

// Cookie Alert
clr-alert#cookie-alert {
  .alert-app-level.alert-warning {
    background-color: $br-midnight-blue;

    .close {
      margin: auto 0.5rem;
    }
  }

  .alert-app-level .alert-items {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .alert-item {
      .btn {
        min-width: 2rem;
      }
    }
  }
}

.swal-modal.blue-header-modal{
  text-align: left;
  .swal-title{
    margin: 0;
    text-align: left;
    background-color: #0072a3;
    color: white;
    font-size: 20px;
    font-weight: normal;
    border-radius: 5px 5px 0px 0px;
  }
  .swal-text{
    padding-left: 20px;
    margin-top: 25px;
  }
  .swal-content{
    margin-top: 10px;
  }
  .swal-button--confirm{
    margin: 0;
    background-color: #0072a3;
    color: white;
  }
  .swal-button--confirm.swal-button--loading{
    color: #0072a3;
  }
  .swal-button__loader div{
    vertical-align: middle;
  }
}

.datagrid-table .datagrid-cell:focus,
 .datagrid-table .datagrid-column:focus{
  outline: -webkit-focus-ring-color solid 0rem !important;
}