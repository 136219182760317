@font-face {
  font-family: 'Kievit SC Offc Book';
  src: url('KievitScOffc-BookIta.eot');
  src: url('KievitScOffc-BookIta.eot?#iefix') format('embedded-opentype'),
  url('KievitScOffc-BookIta.woff2') format('woff2'),
  url('KievitScOffc-BookIta.woff') format('woff'),
  url('KievitScOffc-BookIta.ttf') format('truetype'),
  url('KievitScOffc-BookIta.svg#KievitScOffc-BookIta') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Kievit SC Offc Book';
  src: url('KievitScOffc-Book.eot');
  src: url('KievitScOffc-Book.eot?#iefix') format('embedded-opentype'),
  url('KievitScOffc-Book.woff2') format('woff2'),
  url('KievitScOffc-Book.woff') format('woff'),
  url('KievitScOffc-Book.ttf') format('truetype'),
  url('KievitScOffc-Book.svg#KievitScOffc-Book') format('svg');
  font-weight: 500;
  font-style: normal;
}
