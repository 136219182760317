/************************************************
 * Broadridge Palette
 */

// Blues
$br-midnight-blue: #003657;
$br-blue: #00578e;
$br-blue-alt: #004D78;
$br-dark-cyan: #007bb6;
$br-mid-cyan: #559bc3;
$br-cyan: #00a3d6;
$br-cyan-alt: #0DA8D8;
$br-cyan-light: #51c1e4;

// Neutral
$br-black: #111111;
$br-text-dark: #3c3c3c;
$br-text-regular: #6d6d6d;
$br-text-faded: #b7b7b7;
$br-gray-medium: #999999;
$br-gray-medium-light: #cccccc;
$br-gray-light: #DDDDDD;
$br-gray-extra-light: #f9f9f9;
$br-white: #ffffff;

// Accent
$br-green-darkest: #3a6227;
$br-green-darker: #416e2c;
$br-green-dark: #4F8636;
$br-green-text: #96A100;
$br-green-light: #A8B400;
$br-text-success: #2F8400;

$br-text-danger: #E12200;

/************************************************
 * Additional Variables
 */

// Footer
$footer-height: 30px;
$footer-font-size: 12px;

// Text Colors
$br-alt-text-dark: #333333;
$br-alt-text-light: #abbabe;

// Element Colors
$alt-br-blue-btn: #127bb4;
$border-color: #ccc;
$border-radius: 0.125rem;

// Table
$table-header-icon-size: 0.8rem;

// Box Shadow
$box-shadow-sm: 0 2px 4px 0 rgba(0,0,0,0.10);
$box-shadow-md: 0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08);
$box-shadow-lg: 0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08);
$box-shadow-inner: inset 0 2px 4px 0 rgba(0,0,0,0.06);
$box-shadow-outline: 0 0 0 3px rgba(52,144,220,0.5);
$box-shadow-clr: 0 3px 0 0 $border-color;
$box-shadow-br: 0 1px 3px 0 rgba(0,0,0,0.2);
$box-shadow-br-alt: 0 1px 3px 0 rgba(0,0,0,0.5);
$box-shadow-br-alt-hover: 0 1px 3px 0 rgba(0,0,0,0.8);
