@import "variables";

$clr-header-5-bg-color: $br-midnight-blue;

$clr-btn-success-bg-color: $br-green-dark;
$clr-btn-success-hover-bg-color: $br-green-darker;
$clr-btn-success-box-shadow-color: $br-green-darkest;

$clr-modal-content-padding-left: 60px;
$clr-modal-content-padding-right: 60px;
$clr-modal-content-padding-top: 40px;
$clr-modal-content-padding-bottom: 40px;

$clr-modal-sm-width: 16rem;
